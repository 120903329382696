/** @jsxImportSource theme-ui */
import React from "react";
import Link from "./Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrevNextPage = ({ children, link, side }) => (
  <Link
    to={link}
    sx={{
      variant: "prevNext.box",
    }}
  >
    <FontAwesomeIcon
      sx={{
        order: side !== "right" ? null : 2,
        ml: side !== "right" ? 0 : 3,
        mr: side !== "right" ? 3 : 0,
      }}
      icon={`chevron-${side}`}
      size="2x"
    />
    <div sx={{ variant: "prevNext.content", textAlign: [null, null, side] }}>
      {children}
    </div>
  </Link>
);

export const PrevPage = (props) => <PrevNextPage side="left" {...props} />;
export const NextPage = (props) => <PrevNextPage side="right" {...props} />;
