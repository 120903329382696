/** @jsxImportSource theme-ui */
import React from "react";
import Content from "../../../components/Content";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { PrevPage, NextPage } from "../../../components/PrevNextPage";
import { formatLocalDateTime } from "../../../utils/dateTime";
import { Container, BaseStyles } from "theme-ui";

const Alarm = ({
  content,
  contentComponent,
  title,
  location,
  calledAt,
  type,
  pageLocation,
  prev,
  next,
}) => {
  const ContentRenderer = contentComponent || Content;
  let ContentOrDefault = <ContentRenderer content={content} />;
  if (
    content === null ||
    (typeof content === "string" && content.length <= 0)
  ) {
    ContentOrDefault = <p>Kein Einsatzbericht vorhanden.</p>;
  }

  let numberOfRefs = [prev, next].filter((x) => x != null).length;

  return (
    <BaseStyles>
      <Breadcrumbs names={["Einsätze", title]} location={pageLocation} />
      <div sx={{ variant: "layout.grid.prevNext", "--numRows": numberOfRefs }}>
        {prev && (
          <div sx={{ variant: "layout.grid.prev" }}>
            <PrevPage link={prev.fields.slug}>
              <span sx={{ variant: "prevNext.meta" }}>vorheriger Einsatz:</span>
              <div sx={{ variant: "prevNext.text" }}>
                {prev.frontmatter.title}, {prev.frontmatter.location}
              </div>
            </PrevPage>
          </div>
        )}
        <div sx={{ variant: "layout.grid.main" }}>
          <Container>
            <article>
              <header>
                <h2>Einsatz: {title}</h2>
              </header>
              <main>
                <table>
                  <thead>
                    <tr>
                      <th>Einsatzart:</th>
                      <th>Einsatzort:</th>
                      <th>Alarmierung um:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{type}</td>
                      <td>{location}</td>
                      <td>
                        <time dateTime={calledAt}>
                          {formatLocalDateTime(calledAt)}
                        </time>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {ContentOrDefault}
              </main>
            </article>
          </Container>
        </div>
        {next && (
          <div sx={{ variant: "layout.grid.next" }}>
            <NextPage link={next.fields.slug}>
              <span sx={{ variant: "prevNext.meta" }}>folgender Einsatz:</span>
              <div sx={{ variant: "prevNext.text" }}>
                {next.frontmatter.title}, {next.frontmatter.location}
              </div>
            </NextPage>
          </div>
        )}
      </div>
    </BaseStyles>
  );
};

export default Alarm;
