import React from "react";
import { graphql } from "gatsby";
import Seo from "../../../components/Seo";
import Layout from "../../../components/Layout";
import { HTMLContent } from "../../../components/Content";
import { formatLocalDateTime } from "../../../utils/dateTime";
import Alarm from "./Alarm";

const Template = ({ data, location: pageLocation }) => {
  const { alarm, prevAlarm, nextAlarm } = data;
  const {
    html,
    frontmatter: { title, calledAt, type, location },
  } = alarm;

  return (
    <Layout>
      <Seo
        title={`${title} | Einsätze`}
        pathname={pageLocation.pathname}
        description={`Einsatz - ${title} in ${location} am ${formatLocalDateTime(
          calledAt
        )}`}
      />
      <Alarm
        content={html}
        contentComponent={HTMLContent}
        title={title}
        location={location}
        calledAt={calledAt}
        type={type}
        pageLocation={pageLocation}
        prev={prevAlarm}
        next={nextAlarm}
      />
    </Layout>
  );
};

export default Template;

export const query = graphql`
  query alarmsPage($id: String!, $prevId: String, $nextId: String) {
    alarm: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        location
        calledAt
        type
      }
    }
    prevAlarm: markdownRemark(id: { eq: $prevId }) {
      fields {
        slug
      }
      frontmatter {
        title
        location
        calledAt
      }
    }
    nextAlarm: markdownRemark(id: { eq: $nextId }) {
      fields {
        slug
      }
      frontmatter {
        title
        location
        calledAt
      }
    }
  }
`;
